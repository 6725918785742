<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-grid">
                <div class="p-col-12 p-md-6">
                    <h5>{{ $t('menu.editShop') }}</h5>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
                            >{{ $t('system.active') }}
                            <Button icon="pi  pi-info-circle" class="p-button-rounded p-button-text p-button-info p-mr-2 p-mb-2" v-tooltip="$t('system.infoText.shopStatus')" />
                        </label>
                        <div class="p-col-12 p-md-6">
                            <InputSwitch v-model="shop.active" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.code') }} *</label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="shop.code" :disabled="this.isEdit()" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="shop.name" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.county') }} </label>
                        <div class="p-col-12 p-md-6">
                            <Dropdown style="width: 100%" type="text" v-model="countiesModel" optionLabel="nume" :options="counties" :filter="!this.isMobile()" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.city') }} </label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="shop.city" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.address') }} </label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="shop.address" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.region') }} </label>
                        <div class="p-col-12 p-md-6">
                            <Dropdown style="width: 100%" v-model="regionModel" optionLabel="name" :options="regions" :placeholder="$t('dropdown.placeholder.regions')" :filter="!this.isMobile()" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.franchise') }} </label>
                        <div class="p-col-12 p-md-6">
                            <Dropdown :showClear="true" style="width: 100%" v-model="franchiseModel" optionLabel="name" :options="franchises" :placeholder="$t('dropdown.placeholder.franchise')" :filter="!this.isMobile()" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <div class="p-col-12">
                            <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6">
                    <h5>{{ $t('system.contactPerson') }}</h5>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.name') }}</label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="shop.contacts[0].name" />
                        </div>
                    </div>
                    <div class="p-field p-grid p-mb-4">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.telephone') }} </label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="shop.contacts[0].telephone" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('system.personalid') }} </label>
                        <div class="p-col-12 p-md-6">
                            <InputText style="width: 100%" type="text" v-model="shop.contacts[0].personalIdNumber" />
                        </div>
                    </div>
                    <h5>{{ $t('system.statusDates') }}</h5>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
                            >{{ $t('system.openingDate') }}
                            <Button icon="pi  pi-info-circle" class="p-button-rounded p-button-text p-button-info p-mr-2 p-mb-2" v-tooltip="$t('system.infoText.shopAuthorizeDate')" />
                        </label>
                        <div class="p-col-12 p-md-6">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showIcon="true" :showButtonBar="true" v-model="shop.openingDate"></Calendar>
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"
                            >{{ $t('system.closingDate') }}
                            <Button icon="pi  pi-info-circle" class="p-button-rounded p-button-text p-button-info p-mr-2 p-mb-2" v-tooltip="$t('system.infoText.shopCloseDate')" />
                        </label>
                        <div class="p-col-12 p-md-6">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showIcon="true" :showButtonBar="true" v-model="shop.closingDate"></Calendar>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <Button v-show="showReopen" :label="$t('system.reopenShop')" @click="reopenShop()" class="p-button-info p-button-text p-mr-2 p-mb-2" />
                        </div>
                    </div>
                </div>

                <Fieldset :legend="$t('system.thirdPartiesMappings')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.provider') }}1 </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="shop.nsoftLocationId" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.provider') }}2 </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="shop.winnerLocationId" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.provider') }}3 </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="shop.syncronBetShopId" />
                        </div>
                    </div>
                </Fieldset>
                <Fieldset :legend="$t('system.financialDetails')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-field p-grid p-mb-4">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.limit') }} </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="shop.limit" />
                        </div>
                    </div>
                    <div class="p-field p-grid p-mb-4">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.currentSold') }} </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="shop.currentSold" />
                        </div>
                    </div>
                    <div class="p-field p-grid p-mb-4">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.soldLimit') }} </label>
                        <div class="p-col-12 p-md-4">
                            <InputText style="width: 100%" type="text" v-model="shop.soldLimit" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('menu.collaborationPlan') }} </label>
                        <div class="p-col-12 p-md-4">
                            <Dropdown :showClear="true" style="width: 100%" v-model="collaborationPlanModel" optionLabel="name" :options="collaborationPlans" :placeholder="$t('dropdown.placeholder.franchise')" :filter="!this.isMobile()" />
                        </div>
                    </div>
                </Fieldset>
                <Fieldset :legend="$t('system.blockSettings')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.blocked') }} </label>
                        <div class="p-col-12 p-md-4">
                            <Dropdown :showClear="true" style="width: 100%" v-model="blockedModel" optionLabel="option" :options="blockedOptions" :placeholder="$t('dropdown.placeholder.franchise')" :filter="!this.isMobile()" />
                        </div>
                    </div>
                    <div v-for="(product, index) in this.shop.blockedProducts" :key="'product' + index" class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0"> {{ getProductName(product.name) }}</label>
                        <div class="p-col-12 p-md-4">
                            <InputSwitch v-model="product.isBlocked" />
                        </div>
                    </div>
                </Fieldset>
                <transition-group name="p-message" tag="div" class="p-col-12 p-mb-2">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import settings from '../../settings/generalSettings';
import countiesJS from '../../static/counties';
export default {
    data() {
        return {
            BoApi: new BoApi(),
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'shops',
            errorKeyCount: 0,
            shop: {
                active: false,
                contacts: [{}],
            },
            regionModel: {},
            rolesModel: [],
            franchiseModel: '',
            collaborationPlans: [],
            regions: [],
            franchises: [],
            collaborationPlanModel: {},
            successReset: false,
            passwordKeyCount: 0,
            blockedProductsList: settings.blockedProductsList,
            blockedModel: {
                id: 1,
                option: 'Blocked',
            },
            showReopen: false,
            counties: countiesJS['countiesArray'],
            countiesModel: [],
        };
    },
    computed: {
        blockedOptions() {
            return [
                {
                    id: -1,
                    option: this.$t('general.all'),
                },
                {
                    id: 0,
                    option: this.$t('system.notBlocked'),
                },
                {
                    id: 1,
                    option: this.$t('system.blocked'),
                },
                {
                    id: 2,
                    option: this.$t('system.blockedPayments'),
                },
                {
                    id: 3,
                    option: this.$t('system.blockedIncomes'),
                },
            ];
        },
    },
    mounted() {
        this.BoApi.getCollaborationPlans()
            .then((responsePlans) => {
                this.collaborationPlans = responsePlans.data;
                this.BoApi.getRegionsDdl().then((responseRegions) => {
                    this.regions = responseRegions.data;
                    this.BoApi.getBUnitsDdl().then((responseFranchises) => {
                        this.franchises = responseFranchises.data;
                        if (this.isEdit()) {
                            this.BoApi.getShopDetails(this.$route.params.id).then((response) => {
                                this.shop = response.data;
                                this.shop.openingDate = new Date(response.data.openingDate);
                                this.shop.closingDate = this.notNullOrEmptyField(response.data.closingDate) ? new Date(response.data.closingDate) : response.data.closingDate;
                                this.countiesModel = this.shop.county ? this.counties.find((c) => c.nume === this.shop.county) : {};

                                this.blockedModel = this.blockedOptions.find((c) => c.id === this.shop.isBlocked);
                                this.franchiseModel = this.notNullOrEmptyField(this.shop.franchiseId) ? this.franchises.find((b) => b._id === this.shop.franchiseId) : {};
                                this.regionModel = this.notNullOrEmptyField(this.shop.regionId) ? this.regions.find((b) => b._id === this.shop.regionId) : {};
                                this.collaborationPlanModel = this.notNullOrEmptyField(this.shop.collaborationPlan) ? this.collaborationPlans.find((b) => b.id === this.shop.collaborationPlan) : {};
                                if (this.shop.closingDate != null) {
                                    this.showReopen = true;
                                }
                            });
                        } else {
                            this.shop.blockedProducts = this.blockedProductsList;
                            this.shop.limit = 500;
                            this.shop.soldLimit = 5000;
                            this.shop.currentSold = 0;
                        }
                    });
                });
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
                this.errorText = error;
                this.showError = true;
            });
    },
    methods: {
        submitForm: function () {
            this.showError = false;
            this.disableSubmit = true;
            this.shop.county = this.notNullOrEmptyField(this.countiesModel.nume) ? this.countiesModel.nume : '';
            this.shop.franchiseId = this.notNullOrEmptyObject(this.franchiseModel) ? this.franchiseModel._id : null;
            this.shop.regionId = this.notNullOrEmptyObject(this.regionModel) ? this.regionModel._id : null;
            this.shop.collaborationPlan = this.notNullOrEmptyObject(this.collaborationPlanModel) ? this.collaborationPlanModel.id : null;
            this.shop.isBlocked = this.blockedModel.id;
            this.shop.openingDate = this.formatEnDate(this.shop.openingDate);
            this.shop.closingDate = this.notNullOrEmptyField(this.shop.closingDate) ? this.formatEnDate(this.shop.closingDate) : null;
            if (this.notNullOrEmptyField(this.shop.name) && this.notNullOrEmptyField(this.shop.code)) {
                if (
                    !settings.useCNPValidation ||
                    (settings.useCNPValidation && (!this.notNullOrEmptyField(this.shop.contacts[0].personalIdNumber) || (this.notNullOrEmptyField(this.shop.contacts[0].personalIdNumber) && this.validateCNP(this.shop.contacts[0].personalIdNumber))))
                ) {
                    console.log(this.shop);
                    this.BoApi.saveShop(this.shop)
                        .then(() => {
                            this.goto(this.backRoute);
                        })
                        .catch((error) => {
                            this.disableSubmit = false;
                            this.checkForUnauthorizedError(error);
                            if (error.response.data == 'USED_SHOP_CODE') {
                                this.errorText = this.$t('system.alerts.shopCodeUsed');
                            } else {
                                this.errorText = error;
                            }
                            this.showError = true;

                            this.errorKeyCount++;
                        });
                } else {
                    this.errorText = this.$t('system.alerts.invalidCNP');
                    this.showError = true;
                    this.disableSubmit = false;
                    this.errorKeyCount++;
                }
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        reopenShop() {
            this.shop.closingDate = null;
            this.submitForm();
        },
    },
};
</script>
