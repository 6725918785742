export default {
    countiesArray: [
        {
            'auto': 'AB',
            'nume': 'Alba'
        },
        {
            'auto': 'AR',
            'nume': 'Arad'
        },
        {
            'auto': 'AG',
            'nume': 'Argeş'
        },
        {
            'auto': 'BC',
            'nume': 'Bacău'
        },
        {
            'auto': 'BH',
            'nume': 'Bihor'
        },
        {
            'auto': 'BN',
            'nume': 'Bistriţa-Năsăud'
        },
        {
            'auto': 'BT',
            'nume': 'Botoşani'
        },
        {
            'auto': 'BR',
            'nume': 'Brăila'
        },
        {
            'auto': 'BV',
            'nume': 'Braşov'
        },
        {
            'auto': 'B',
            'nume': 'Bucureşti'
        },
        {
            'auto': 'BZ',
            'nume': 'Buzău'
        },
        {
            'auto': 'CL',
            'nume': 'Călăraşi'
        },
        {
            'auto': 'CS',
            'nume': 'Caraş-Severin'
        },
        {
            'auto': 'CJ',
            'nume': 'Cluj'
        },
        {
            'auto': 'CT',
            'nume': 'Constanţa'
        },
        {
            'auto': 'CV',
            'nume': 'Covasna'
        },
        {
            'auto': 'DB',
            'nume': 'Dâmboviţa'
        },
        {
            'auto': 'DJ',
            'nume': 'Dolj'
        },
        {
            'auto': 'GL',
            'nume': 'Galaţi'
        },
        {
            'auto': 'GR',
            'nume': 'Giurgiu'
        },
        {
            'auto': 'GJ',
            'nume': 'Gorj'
        },
        {
            'auto': 'HR',
            'nume': 'Harghita'
        },
        {
            'auto': 'HD',
            'nume': 'Hunedoara'
        },
        {
            'auto': 'IL',
            'nume': 'Ialomiţa'
        },
        {
            'auto': 'IS',
            'nume': 'Iaşi'
        },
        {
            'auto': 'IF',
            'nume': 'Ilfov'
        },
        {
            'auto': 'MM',
            'nume': 'Maramureş'
        },
        {
            'auto': 'MH',
            'nume': 'Mehedinţi'
        },
        {
            'auto': 'MS',
            'nume': 'Mureş'
        },
        {
            'auto': 'NT',
            'nume': 'Neamţ'
        },
        {
            'auto': 'OT',
            'nume': 'Olt'
        },
        {
            'auto': 'PH',
            'nume': 'Prahova'
        },
        {
            'auto': 'SJ',
            'nume': 'Sălaj'
        },
        {
            'auto': 'SM',
            'nume': 'Satu Mare'
        },
        {
            'auto': 'SB',
            'nume': 'Sibiu'
        },
        {
            'auto': 'SV',
            'nume': 'Suceava'
        },
        {
            'auto': 'TR',
            'nume': 'Teleorman'
        },
        {
            'auto': 'TM',
            'nume': 'Timiş'
        },
        {
            'auto': 'TL',
            'nume': 'Tulcea'
        },
        {
            'auto': 'VL',
            'nume': 'Vâlcea'
        },
        {
            'auto': 'VS',
            'nume': 'Vaslui'
        },
        {
            'auto': 'VN',
            'nume': 'Vrancea'
        }
    ],

}
